/* -------------------------------------
	Tailwind Initialization
   ------------------------------------- */

	@tailwind base;
	@tailwind components;

/* -------------------------------------
	Module Imports
   ------------------------------------- */

	@import './hamburgers.css';

/* -------------------------------------
	Font Imports
   ------------------------------------- */

	/* --- BMS Humanity --- */
	@font-face {
		font-family: 'BMSHumanity';
		src: url('../fonts/BMSHumanity-light.woff') format('woff');
		font-weight: 300;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'BMSHumanity';
		src: url('../fonts/BMSHumanity-regular.woff') format('woff');
		font-weight: 400;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'BMSHumanity';
		src: url('../fonts/BMSHumanity-medium.woff') format('woff');
		font-weight: 700;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'bmsicons';
		src: url('../fonts/bmsicons.eot?84961939');
		src: url('../fonts/bmsicons.eot?84961939#iefix') format('embedded-opentype'),
			 url('../fonts/bmsicons.woff2?84961939') format('woff2'),
			 url('../fonts/bmsicons.woff?84961939') format('woff'),
			 url('../fonts/bmsicons.ttf?84961939') format('truetype'),
			 url('../fonts/bmsicons.svg?84961939#bmsicons') format('svg');
		font-weight: normal;
		font-style: normal;
	}

/* -------------------------------------
	Fontello
   ------------------------------------- */

	[class^="icon-"]:before, [class*=" icon-"]:before {
		font-family: "bmsicons";
		font-style: normal;
		font-weight: normal;
		speak: never;

		display: inline-block;
		text-decoration: inherit;
		width: 1em;
		margin-right: .2em;
		text-align: center;
		/* opacity: .8; */

		/* For safety - reset parent styles, that can break glyph codes*/
		font-variant: normal;
		text-transform: none;

		/* fix buttons height, for twitter bootstrap */
		line-height: 1em;

		/* Animation center compensation - margins should be symmetric */
		/* remove if not needed */
		margin-left: .2em;

		/* Font smoothing. That was taken from TWBS */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.icon-chevron:before { content: '\f006'; } /* '' */

/* -------------------------------------
	Hamburger Overrides
   ------------------------------------- */

	.hamburger {
		.hamburger-box {
			.hamburger-inner,
			.hamburger-inner:before,
			.hamburger-inner:after {
				background-color: #BE2BBB;
			}
		}
	}

	.hamburger.is-active {
		.hamburger-box {
			.hamburger-inner,
			.hamburger-inner:before,
			.hamburger-inner:after {
				background-color: #BE2BBB;
			}
		}
	}

/* -------------------------------------
	Swiper Overrides
   ------------------------------------- */

	.swiper-pagination-bullet-active {
		background-color: #BE2BBB !important;
	} 

/* -------------------------------------
	Main Menu / Content Container
   ------------------------------------- */

	.wrapper {
		transform: translate3d(-220px, 0, 0);

		@media screen and (min-width: 768px) {
			transform: translate3d(0, 0, 0);
		}
	}

	.wrapper.menu-open {
		transform: translate3d(0, 0, 0);
	}

	.header {
		transform: translate3d(0, 0, 0);

		@media screen and (min-width: 768px) {
			transform: translate3d(-88px, 0, 0);
		}
	}

 /* -------------------------------------
	Cookie Consent Overrides
   ------------------------------------- */

	.cc-window {
		font-family: 'Nunito Sans', sans-serif;
		line-height: 1;
		box-shadow: 0 0px 15px -6px rgba(0,0,0,0.4);
		max-width: 1920px;
	}

	.cc-compliance .cc-btn {
		padding: 0.75em 1.25em;
	}

	.cc-window.cc-banner {
		padding: 0.5em;
		padding-left: 1em;
	}

/* -------------------------------------
	Prose Overrides
   ------------------------------------- */

	.prose {
		max-width: none;

		li {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

/* -------------------------------------
	Bullets
   ------------------------------------- */

	.bullets ul > li {
		position: relative;
		padding-left: 1.75em;
	}

	.bullets ul > li::before {
		content: "";
		position: absolute;
		background-color: #AFAFAF;
		border-radius: 50%;
		width: 0.375em;
		height: 0.375em;
		top: calc(0.875em - 0.45em);
		left: 0.25em;
	}

/* -------------------------------------
	Reference Indent
   ------------------------------------- */

	.references {
		.pl-1 { text-indent: -0.5em; }
		.pl-1:first-line { text-indent: 0; }
	}

/* -------------------------------------
	Email sign up
   ------------------------------------- */

	.is-hidden {
		display: none !important;
	}

	@keyframes rotate {
		100% { transform: rotate(360deg); }
	}

	@keyframes dash {
		0% { stroke-dasharray: 1,200; stroke-dashoffset: 0; }
		50% { stroke-dasharray: 89,200; stroke-dashoffset: -35; }
		100% { stroke-dasharray: 89,200; stroke-dashoffset: -124; }
	}

	.loading {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.loading-spinner {
		width: 50px;
		height: 50px;
	}

	.loading-spinner svg {
		position: relative;
		animation: rotate 2s linear infinite;
		height: 50px;
		width: 50px;
	}

	.loading-spinner circle {
		stroke: #BE2BBB;
		stroke-dasharray: 1,200;
		stroke-dashoffset: 0;
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}

/* -------------------------------------
	Game Elements
   ------------------------------------- */

	.game-container{
		.game-page {
			display: none;
		}

		.game-page.current-page {
			display: block;
		}
	}

	.game-pagination {
		.step {
			border-color: #d1d1d1;
			color: #4d4d4d;
		}

		.step.current-step {
			border-color: #BE2BBB;
			color: #BE2BBB;
		}
	}

/* -------------------------------------
	Tabs
   ------------------------------------- */

	.label {
		transition: background 0.25s, color 0.25s, width 0.25s;

		.input:focus + & { z-index: 1; }

		.input:checked + & {
			background: #222;
			color: #fafafa;
		}
	}

	.panel {
		.input:checked + .label + & {
			display: block;
		}
	}

/* -------------------------------------
	Accordion
   ------------------------------------- */

	/* Panel style */
	.accordion .a-container .a-panel {
		-webkit-transition: all 300ms ease-in-out;
				transition: all 300ms ease-in-out;

		max-height: 0;
	}

	.accordion .a-container .a-btn i {
		-webkit-transition: transform 300ms ease-in-out;
				transition: transform 300ms ease-in-out;
	}

	/* Panel style when active */
	.accordion .a-container.active .a-panel {
		padding: 0.5rem 0 1rem 0;
		opacity: 1;
		max-height: 100%;

		-webkit-transition: all 300ms ease-in-out;
				transition: all 300ms ease-in-out;
	}

	.accordion .a-container.active .a-btn {
		color: #BE2BBB;
	}

	.accordion .a-container.active .a-btn i {
		transform: rotate(90deg);

		-webkit-transition: transform 300ms ease-in-out;
				transition: transform 300ms ease-in-out;
	}

/* -------------------------------------
	Modal Style Utility [WIP]
   ------------------------------------- */

	.modal {
		transition: opacity 0.25s ease;
	}

	body.modal-active {
		overflow-x: hidden;
		overflow-y: hidden !important;
	}

	@media (min-width: 768px) {
		body.modal-active {
			overflow-y: visible !important;
		}
	}

/* -------------------------------------
	Force Wrap
   ------------------------------------- */

	.dont-break-out {
		/* These are technically the same, but use both */
		overflow-wrap: break-word;
		word-wrap: break-word;

		-ms-word-break: break-all;
		/* This is the dangerous one in WebKit, as it breaks things wherever */
		word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;

		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}

/* -------------------------------------
	Clean Select
   ------------------------------------- */

	.clean-select,
	.clean-select:active,
	.clean-select:focus {
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-webkit-appearance: none;
		-webkit-border-radius: 0px;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		outline: none;
	}

/* -------------------------------------
	Backdrop Filter
   ------------------------------------- */

	.backdrop-blur {
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
	}

/* -------------------------------------
	Diagnosing HCM Amber Swiper Controls
   ------------------------------------- */

	.amber-swiper-control {
		width: 100%;
		height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	#amber-swiper-one {
		background-color: #FFECCD;
		border-top-left-radius: 9999px;

		&:focus { border-top-left-radius: 9999px; }
		&:hover {
			border-top-left-radius: 9999px;
			background-color: #FFE2B3;
			box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.02);
		}
		&:active {
			border-top-left-radius: 9999px;
			box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1);
		}
	}

	#amber-swiper-one.amber-swiper-control-active {
		color: #D79E40;
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.15);

		&:focus,
		&:hover,
		&:active {
			cursor: default;
			background-color: #FFECCD;
			box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.15);
		}
	}

	#amber-swiper-two {
		background-color: #FEDCCA;
		border-top-right-radius: 9999px;

		&:focus { border-top-right-radius: 9999px; }
		&:hover {
			border-top-right-radius: 9999px;
			background-color: #FECBB1;
			box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.02);
		}
		&:active {
			border-top-right-radius: 9999px;
			box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1);
		}
	}

	#amber-swiper-two.amber-swiper-control-active {
		color: #CB794E;
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.15);

		&:focus,
		&:hover,
		&:active {
			cursor: default;
			background-color: #FEDCCA;
			box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.15);
		}
	}

	#amber-swiper-three {
		background-color: #DFCBC3;
		border-bottom-right-radius: 9999px;

		&:focus { border-bottom-right-radius: 9999px; }
		&:hover {
			border-bottom-right-radius: 9999px;
			background-color: #D6BDB2;
			box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.02);
		}
		&:active {
			border-bottom-right-radius: 9999px;
			box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1);
		}
	}

	#amber-swiper-three.amber-swiper-control-active {
		color: #767676;
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.15);

		&:focus,
		&:hover,
		&:active {
			cursor: default;
			background-color: #DFCBC3;
			box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.15);
		}
	}

	#amber-swiper-four {
		background-color: #EAD5C9;
		border-bottom-left-radius: 9999px;

		&:focus { border-bottom-left-radius: 9999px; }
		&:hover {
			border-bottom-left-radius: 9999px;
			background-color: #E3C7B7;
			box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.02);
		}
		&:active {
			border-bottom-left-radius: 9999px;
			box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1);
		}
	}

	#amber-swiper-four.amber-swiper-control-active {
		color: #8B8079;
		box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.15);

		&:focus,
		&:hover,
		&:active {
			cursor: default;
			background-color: #EAD5C9;
			box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.15);
		}
	}

/* -------------------------------------
	Tailwind Utilities (keep at end)
   ------------------------------------- */

	@tailwind utilities;